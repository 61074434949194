import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  & .sublockin-select__control {
    color: #001c72 !important;
    background-color: #eef0f7 !important;
  }
  & .sublockin-select__menu {
    color: #001c72 !important;
    background-color: #eef0f7 !important;
  }
`;

export const Label = styled.div`
  margin-bottom: 5px;
  & > span {
    font-weight: bold;
    color: #bc2e3e;
  }
`;
